import React from 'react';
import "./footer.style.css";
import { Container, Row, Col } from 'react-bootstrap';
import { images, icons } from '../../constants';

const Footer = () => {
  return (
    <div className='footerWrepper'>
        <Container>
            <Row>
                <Col lg={5} md={12}>
                    <Row className='align-items-start'>
                        <Col lg={2}>
                            <img src={images.logo} className='logo' />
                        </Col>
                        <Col lg={10}>
                            <h2 className='title'>Pakistan Oil Mills</h2>
                            <p className='about'>
                                Pakistan Oil Mills which comes under the umbrella of highly successful , responsive and dynamic group of companies, which invest in building wining brands that continuously seek to create value for the customers.
                            </p>
                            <div className='d-flex social'>
                                <a href='https://pakistanoilmills.com/' target='_blank'><img src={icons.google} /></a>
                                <a href='https://www.facebook.com/PureCookingOilOfficial/' target='_blank'><img src={icons.facebook} /></a>
                                <a href='https://www.instagram.com/purecookingoilofficial/'  target='_blank'><img src={icons.instagram} /></a>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={1}>
                </Col>
                <Col lg={2} >
                    <p className='heading'>About Pom</p>
                    <ul className='data'>
                        <li><a href='/'>Home</a></li>
                        <li><a href='/about'>About</a></li>
                        <li><a href='/products'>Products</a></li>
                    </ul>
                </Col>
                <Col lg={2} >
                    <p className='heading'>Products</p>
                    <ul className='data'>
                        <li><a href='/products/cookingOil/pure-cooking-oil'>Pure Cooking Oil</a></li>
                        <li><a href='/products/banaspati/naz-banaspati'>Naz Banaspati</a></li>
                        <li><a href='/products/margarine/kawality-margarine'>Kawality Margarine</a></li>
                    </ul>
                </Col>
                <Col lg={2} md={12} className='instagram'>
                    <p className='heading'>Instagram</p>
                    <Row>
                        <Col lg={6} md={6} sm={6} xs={6}>
                            <img src={images.ad1} />
                        </Col>
                        <Col lg={6}  md={6} sm={6} xs={6}>
                            <img src={images.ad2} />
                        </Col>
                        <Col lg={6}  md={6} sm={6} xs={6}>
                            <img src={images.ad3} />
                        </Col>
                        <Col lg={6}  md={6} sm={6} xs={6}>
                            <img src={images.ad4} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
        <div className='tagline'>
            <p>© 2025 Pakistan Oil Mills. All Rights Reserved.</p>
        </div>
    </div>
  )
}

export default Footer
