import { images } from "../constants";

const cookingOil = [
    {
        name: "Naz Cooking Oil",
        headline: "Naz cooking oil, where every drop cares for your family.",
        tag: "Hame Naz he ke ham hen Pakistan",
        desc: "In a world full of options, not every oil looks after your health. But Naz Cooking Oil is different, it is made to nourish, protect, and uplift your daily meals with care.<br/><br/>Crafted with a heart-smart formula, it’s free from cholesterol and trans-fat, making it a safe, healthy choice for your loved ones. Blended with the power of vitamin A & D, it supports clearer vision, stronger bones, and better growth. Essential for growing kids and aging parents alike.<br/>Feel the lightness in every bite. Naz Cooking Oil is expertly refined for low oil absorption, so your meals are crispy, delicious, and never heavy on the stomach.<br/>Your health matters, that’s why we keep 100% pure, safe and chemical free. No shortcuts, no compromises.<br/>Backed by science, trusted by families. Naz Cooking Oil isn’t just oil, it’s daily nutrition your body can feel.<br/><br/>Choose Naz Cooking Oil, because your kitchen deserves more than just cooking oil.",
        ctg: "Cooking Oil",
        image: images.NazCookingOil,
        thumbnail: images.NazCookingOilThumbnail,
        thumbnail2: images.NazCookingOilThumbnail2,
        packing: {
            tins: {
                Tin_2_5_ltr: "2.5 Litre",
                Tin_5ltr: "5 Litre",
                Tin_10ltr: "10 Litre",
                Tin_16ltr: "16 Litre",
                Can_10ltr: "10 Litre",
            },
            // cartons: {
            //     Bottle_1ltr: "1 Litre",
            //     Bottle_3ltr: "3 Litre",
            //     Box_5ltr: "5 Litre",
            // },
            bottle: {
                Bottle_1x5ltr: "1x5 Litre",
                Bottle_half_ltr: "1/2 Litre",
                Bottle_1_ltr: "1 Litre",
                Bottle_3_ltr: "3 Litre",
                Bottle_5_ltr: "5 Litre",
            },
            Cartons: {
                Box_1x5ltr: "1x5 Litre",
                Box_half_ltr: "1/2 Litre",
            }
        }
    },
    {
        name: "Pure Cooking Oil",
        headline: "Pure cooking oil, the heart-healthy choice!",
        tag: "Bandhey Dilo ke Bandhan",
        desc: "Not all oils are created equal. Pure cooking oil is a perfectly balanced blend of soya, canola and sunflower oils, designed to give you pure nutrition without the risk of unhealthy fats.<br><br>Heart friendly formula, zero cholesterol & trans-fat, making it ideal for a healthy heart. Immune-boosting goodness, rich in vitamin A & D3 to support immunity, stronger bones and better eyesight. Light & greasy, less oil absorption means crispy, delicious meals without excess fat. Scientifically baked, research shows that high quality oils can reduce inflammation, lower disease risks, and support overall well-being. 100% pure & chemical-free, no additives, no harmful chemicals, just the natural goodness your body needs.<br><br>Upgrade your kitchen with Pure cooking oil, where purity meets nutrition.<br><br>Cook smart, live healthy and enjoy every bite!",
        ctg: "Cooking Oil",
        image: images.PureCookingOil,
        thumbnail: images.PureCookingOilThumbnail,
        thumbnail2: images.PureCookingOilThumbnail2,
        packing: {
            tins: {
                Tin_2_5ltr: "2.5 Litre",
                Tin_5ltr: "5 Litre",
                Tin_10ltr: "10 Litre",
                Tin_16ltr: "16 Litre",
                Tin_16kg: "16 KG",
                Can_10ltr: "10 Litre",
                Can_16ltr: "16 Litre",
                Can_15kg: "15 KG",
            },
            bottle: {
                Bottle_1ltr: "1 Litre",
                Bottle_3ltr: "3 Litre",
                Bottle_5ltr: "5 Litre",
                Bottle_1x6ltr: "1x6 Litre",
            },
            cartons: {
                Carton_1x2ltr: "1x2 Litre",
                Box_1x5_ltr: "1x5 Litre",
            }
        }
    },
    {
        name: "Fry Ola Cooking Oil",
        headline: "Fry Ola Cooking Oil",
        tag: "Fry-Ola Light, Feel Delight",
        desc: "Fry-Ola Cooking Oil Is Safe, Rich In Taste And Very Light Cooking Oil. The Vitamins A & D Found In Fry-ola Cooking Oil Improve Your Eye Sight And Further Stronger Your Bones That Helps You Grow In A Balanced Way.",
        ctg: "Cooking Oil",
        image: images.FryOlaCookingOil,
        thumbnail: images.FryOlaCookingOilThumbnail,
        thumbnail2: images.FryOlaCookingOilThumbnail2,
        packing: {
            tins: {
                Bottle_2_5ltr: "2.5 Litre",
                Bottle_5ltr: "5 Litre",
                Box_10ltr: "10 Litre",
                Can_10ltr: "10 Litre",
                Tin_16ltr: "16 Litre",
            },
            bottle: {
                Bottle_1ltr: "1 Litre",
                Bottle_3ltr: "3 Litre",
                Box_5ltr: "5 Litre",
            },
            pouches: {
                Bottle_1x5ltr: "1x5 Litre",
                Bottle_half_ltr: "1/2 Litre",
            }
        }
    },
    {
        name: "Pak Cooking Oil",
        headline: "Pak Cooking Oil",
        tag: "Taste the Goodness",
        desc: "Pak Cooking Oil Is Safe, Rich In Taste And Very Light Cooking Oil. The Vitamins A & D Found In Naz Cooking Oil Improve Your Eye Sight And Further Stronger Your Bones That Helps You Grow In A Balanced Way.<br><br>Continuous Usage Of Pak Cooking Oil Balances The Level Of Cholesterol And Protects You From Heart Diseases Which Help You To Reduce The Chances Of Getting Paralyzed.",
        ctg: "Cooking Oil",
        image: images.PakCookingOil,
        thumbnail: images.PakCookingOilThumbnail,
        thumbnail2: images.PakCookingOilThumbnail2,
        packing: {
            
            cartons: {
                Carton_1x10L: "1x10L",
                Carton_900ml: "900ml",
            }
        }
    },
    {
        name: "Sun Cooking Oil",
        headline: "Sun Cooking Oil",
        tag: "An Oil for your Heart",
        desc: "Sun Cooking Oil Is Safe, Rich In Taste And Very Light Cooking Oil. The Vitamins A & D Found In Naz Cooking Oil Improve Your Eye Sight And Further Stronger Your Bones That Helps You Grow In A Balanced Way.<br><br>Continuous Usage Of Sun Cooking Oil Balances The Level Of Cholesterol And Protects You From Heart Diseases Which Help You To Reduce The Chances Of Getting Paralyzed.",
        ctg: "Cooking Oil",
        image: images.SunCookingOil,
        thumbnail: images.SunCookingOilThumbnail,
        thumbnail2: images.SunCookingOilThumbnail2,
        packing: {
            tins: {
                Tin_16L: "16L",
                Tin_4_5L: "4.5L",
                Tin_2_25L: "2.25L",
                Can_9L: "9L",

            },
            bottles: {
                Bottle_3L: "3L",
                Bottle_1L: "1L",
            },
            cartons: {
                Carton_900ml: "900ml",
            }
        }
    },
    {
        name: "Pure Canola Oil",
        headline: "Pure Cooking Oil",
        tag: "Absolutely Pure Canola Oil",
        desc: "Made from premium Canadian oil seeds, featuring the lowest saturated fats, rich in Omega 3 and 6, double refined, and fortified with vitamins A, D & E.",
        ctg: "Canola Oil",
        image: images.pureCanola,
        thumbnail: images.pureCanolaThumbnail,
        thumbnail2: "",
        packing: {
            tins: {
                Bottle_2_5ltr: "2.5 Litre",
                Bottle_5ltr: "5 Litre",
                Box_10ltr: "10 Litre",
                Can_10ltr: "10 Litre",
                Tin_16ltr: "16 Litre",
            },
            bottle: {
                Bottle_1ltr: "1 Litre",
                Bottle_3ltr: "3 Litre",
                Box_5ltr: "5 Litre",
            },
            pouches: {
                Bottle_1x5ltr: "1x5 Litre",
                Bottle_half_ltr: "1/2 Litre",
            }
        }
    },
];

const banaspati = [
    {
        name: "Naz Banaspati",
        headline: "Naz Banaspati",
        tag: "Lazzat Ka Aik Naya Zaiqa",
        desc: "NAZ Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Banaspati",
        image: images.NazBanaspati,
        thumbnail: images.NazBanaspatiThumbnail,
        thumbnail2: "",
        packing: {
            tins: {
                Tin_16kg: "16kg",
                Tin_5kg: "5kg",
                Tin_2_5kg: "2.5kg",
            },
            buckets: {
                Bucket_16kg: "16kg",
                Bucket_10kg: "10kg",
                Bucket_5kg: "5kg",
                Bucket_2_5kg: "2.5kg",
            },
            cartons: {
                Carton_100g: "100g",
                Carton_1kg: "1kg",
                Carton_half_kg: "1/2kg",
                Carton_quarter_kg: "1/4kg",
                Box_1x5kg: "1x5kg",
            }        
        }
    },
    {
        name: "Pure Banaspati",
        headline: "Pure Banaspati",
        tag: "Pure hi jane dil ki baat",
        desc: "Pure Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Banaspati",
        image: images.PureBanaspati,
        thumbnail: images.PureBanaspatiThumbnail,
        thumbnail2: "",
        packing : {
            tins: {
                Tin_16kg: "16 kg",
                Tin_10kg: "10 kg",
                Tin_5kg: "5 kg",
                Tin_2_5kg: "2.5 kg",
            },
            buckets: {
                Bucket_16kg: "16 kg",
                Bucket_10kg: "10 kg",
                Bucket_5kg: "5 kg",
                Bucket_2_5kg: "2.5 kg",
            },
            Cartons: {
                Carton_1kg: "1 kg",
                Carton_half_kg: "1/2 kg",
                Carton_quarter_kg: "1/4 kg",
                Box_1x5kg: "1x5 kg",
            }
        }
    },
    {
        name: "Pak Banaspati",
        headline: "Pak Banaspati",
        tag: "Naya Pack zyada Maza",
        desc: "Pak Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Banaspati",
        image: images.PakBanaspati,
        thumbnail: images.PakBanaspatiThumbnail,
        thumbnail2: "",
        packing: {
            cartons: {
                Carton_1kg: "1kg",
                Carton_half_kg: "1/2kg",
                Carton_quarter_kg: "1/4kg",
            }
        }
    },
    {
        name: "Sun Banaspati",
        headline: "Sun Banaspati",
        tag: "Jab Zaiqa maange Man sab bolen Sun",
        desc: "Sun Banaspati Ghee Is Full Of Taste, Rich In Aroma And Provides You A Healthier Diet Habit.<br><br>It Contains Vitamins A, D & E Which Improves Your Eye Sight, Stronger Your Bones And Good For Your Mental Growth. Research Shows That Banaspati Contains Anti-Viral Elements Which Protect You From Cancer.",
        ctg: "Banaspati",
        image: images.SunBanaspati,
        thumbnail: images.SunBanaspatiThumbnail,
        thumbnail2: "",
        packing: {
            tins: {
                Tin_16kg: "16kg",
                Tin_15_7kg: "15.70kg",
                Tin_4_5kg: "4.5kg",
                Tin_2_25kg: "2.25kg",
            },
            cartons: {
                Carton_900g: "900g",
                Carton_450g: "450g",
                Carton_225g: "225g",
            }
        }
    },
];

const margarine = [
    {
        name: "Kawality Margarine",
        headline: "Kawality Margarine",
        tag: "---",
        desc: "Margarine is an essential ingredient of various bakery items it is mostly use for solid items in bakeries to increase natural taste and freshness the main function of quality margarine is to devide layers of dough in th preperation of paties, samosa (Kharrey) etc. <br /><br /> To provide a layered structure for norishment.",
        ctg: "Margarine",
        image: images.KawalityMargarine,
        thumbnail: images.KawalityMargarineThumbnail,
        thumbnail2: images.KawalityMargarineThumbnail2,
        packing: {
            tins: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_16kg: "10kg",
            },
            buckets: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_10kg: "10kg",
                Box_16kg: "16kg",
            },
            pouches: {
                Bottle_1kg: "1kg",
                Bottle_half_ltr: "1/2kg",
                Bottle_quarter_kg: "1/4kg",
                Bottle_1x5kg: "1x5kg",
                Bottle_100g: "100g",
            }
        }
    },
    {
        name: "Royal Mava Margarine",
        headline: "Royal Mava Margarine",
        tag: "---",
        desc: "Mava is an essential ingredient of various bakery items. it is mostly use for solid items in bakeries to increase natural taste and freshment. The main function of Royal Mava Margarine and Baker's choice Cremo Mava is to devide layers of dough in the preperation of paties, samosa, etc. <br/><br/> To provide a layered structure for norishment.",
        ctg: "Margarine",
        image: images.RoyalMavaMargarine,
        thumbnail: images.RoyalMavaMargarineThumbnail,
        thumbnail2: images.RoyalMavaMargarineThumbnail2,
        packing: {
            tins: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_16kg: "10kg",
            },
            buckets: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_10kg: "10kg",
                Box_16kg: "16kg",
            },
            pouches: {
                Bottle_1kg: "1kg",
                Bottle_half_ltr: "1/2kg",
                Bottle_quarter_kg: "1/4kg",
                Bottle_1x5kg: "1x5kg",
                Bottle_100g: "100g",
            }
        }
    },
    
    {
        name: "Bakers Choice Margarine",
        headline: "Bakers Choice Margarine",
        tag: "---",
        desc: "Baker's choice is a biscuit margarine made with hygenic principle and state of art technology. The biscuits prepared in it give you health and good taste.",
        ctg: "Margarine",
        image: images.RoyalMavaMargarine,
        thumbnail: images.RoyalMavaMargarineThumbnail,
        thumbnail2: images.RoyalMavaMargarineThumbnail2,
        packing: {
            tins: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_16kg: "10kg",
            },
            buckets: {
                Bottle_2_5kg: "2.5kg",
                Bottle_5kg: "5kg",
                Box_10kg: "10kg",
                Box_16kg: "16kg",
            },
            pouches: {
                Bottle_1kg: "1kg",
                Bottle_half_ltr: "1/2kg",
                Bottle_quarter_kg: "1/4kg",
                Bottle_1x5kg: "1x5kg",
                Bottle_100g: "100g",
            }
        }
    },
]

const products = {
    cookingOil : cookingOil,
    banaspati : banaspati,
    margarine : margarine,
}

export default products;
