import "./home.css";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import {images} from "../../constants";
import { Heading, Card, Testimonial } from "../../Components";
import {Products} from "../../Api";
import {formatString} from "../../Common";
import { Swiper, SwiperSlide } from 'swiper/react';
import {SlideshowLightbox} from 'lightbox.js-react'

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


import { Autoplay, Pagination, Navigation  } from 'swiper/modules';
import { useRef } from "react";


const chunkArray = (array, size) => {
    return array.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / size);
        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }
        result[chunkIndex].push(item);
        return result;
    }, []);
};

const Home = () => {

    const itemsPerSlide = 2;
    const slides = chunkArray(Products.cookingOil, itemsPerSlide);
    
    return(
        <div className="homeWrepper">
        <div className="banner ">
            <Container className="contentContainer">
                <Row>
                    <Col lg="6" className="align-content-center content">
                        <div className="bannerHeading">
                        <h1 >Pakistan Oil Mills</h1>
                        <span>Pvt. Ltd</span>
                        </div>
                        <p>Pakistan Oil Mills Products Are Prepared Strictly In Accordance With The Pakistan Standard Quality Control Authority.<br/><br/>Pakistan Oil Mills Has Vast Experience In Edible Oil Business , It Has Established In 1990 With Well Equipped Composite Vegetable Oil And Banaspati Manufacturing Unit And Is Gaining Good Market Share.</p>
                        <div className="button">
                            <a href="/about">
                                <button>Learn More</button>
                            </a>
                        </div>
                    </Col>
                    {/* <Col lg="6"> */}
                        <div className="image" >
                            {/* <img src={images.frame2} className="frame"/> */}
                            <Carousel fade controls={false} indicators={false} pause={false} interval={4000}>
                                <Carousel.Item>
                                <img src={images.ad5} />
                                <div className="subImage" ></div>

                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.ad6} />
                                <div className="subImage" ></div>

                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.ad7} />
                                <div className="subImage" ></div>
                                
                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.ad8} />
                                <div className="subImage" ></div>
                                
                                    
                                </Carousel.Item>
                                <Carousel.Item>
                                <img src={images.ad9} />
                                <div className="subImage" ></div>

                                    
                                </Carousel.Item>
                            </Carousel>
                        </div>
                    {/* </Col> */}
                </Row>
            </Container>
        </div>
        {/* <div className="sliderCarousel">
            <Swiper
                tyle={{
                '--swiper-navigation-color': '#fff',
                '--swiper-pagination-color': '#fff',
                }}
                lazy={true}
                pagination={{
                clickable: true,
                }}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                  }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                className="mySwiper"
            >
                <SwiperSlide>
                    <img
                        src={images.adNew1}
                        loading="lazy"
                    />
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
                <SwiperSlide>
                    <img
                        src={images.adNew2}
                        loading="lazy"
                    />
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"></div>
                </SwiperSlide>
            </Swiper>

        </div> */}

        <Container>
            <div className="calender">
                <Heading title="Calenders" />
                <Row>
                  <Col lg={6}>
                    <div className="contentContainer">
                    <div className="images">
                        <img src={images.pomCalenderThumbnail1} />
                        <img src={images.pomCalenderThumbnail2} />
                        <img src={images.pomCalenderThumbnail3} />
                        <img src={images.pomCalenderThumbnail4} />
                    </div>
                    <div className="button">
                        <a href={images.calender} download>Download Pom Calender 2025</a>
                    </div>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="contentContainer">
                        <div className="images">
                            <img src={images.bakeryCalenderThumbnail1} />
                            <img src={images.bakeryCalenderThumbnail2} />
                            <img src={images.bakeryCalenderThumbnail3} />
                            <img src={images.bakeryCalenderThumbnail4} />
                        </div>
                        <div className="button">
                            <a href={images.bakeryCalender} download>Download Bakery Calender 2025</a>
                        </div>
                    </div>
                  </Col>
                </Row>
            </div>
        </Container>

        <div className="products">
            <Container>
                <Heading title="Products" />
                
                <div className="productsWrepper " >
                    <Row>
                        <Col lg={6} md={12}>
                            <Carousel className="cardGroup " controls={false} indicators={false} interval={5000} pause={false}>
                                {slides.map((group, index) => (
                                    <Carousel.Item key={index}>
                                        <div className="d-flex justify-content-center">
                                            {group.map((oil, idx) => (
                                                <Card key={idx} name={oil.name} image={oil.image} tagline={oil.tag} link={`/products/cookingOil/${formatString(oil.name)}`} />
                                            ))}
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                        <Col lg={3} md={6}>
                            <Carousel controls={false} indicators={false} interval={5000} pause={false}>
                                {Products.banaspati.map((ghee, index) => (
                                    <Carousel.Item key={index}>
                                        <Card name={ghee.name} image={ghee.image} tagline={ghee.tag} link={`/products/banaspati/${formatString(ghee.name)}`} />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                        <Col lg={3} md={6}>
                            <Carousel controls={false} indicators={false} interval={5000} pause={false}>
                                {Products.margarine.map((margarine, index) => (
                                    <Carousel.Item key={index}>
                                        <Card name={margarine.name} image={margarine.image} tagline={margarine.ctg} link={`/products/margarine/${formatString(margarine.name)}`} />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>

        <Testimonial breakpoints={{1500: {slidesPerView: 3}, 600: {slidesPerView: 2}}} slides={1} space={20} title={"Happy Customers"} />

        <div className="news">
            <Container>
                <Heading title={"News & Events"} />

                <div className="videoContainer">
                {/* <SlideshowLightbox className="container grid grid-cols-3 gap-2 mx-auto"> */}
                <video width="100%" controls>
                    <source src={images.video1} type="video/mp4" />
                </video>
                {/* </SlideshowLightbox>  */}
                </div>
            </Container>
        </div>

        <div className="certificates">
            <Container>
                <div className="certificateWrepper">
                <Swiper
                        style={{
                            '--swiper-navigation-color': '#000',
                            }}
                        slidesPerView={2}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        // navigation={true}

                        breakpoints={{800: {slidesPerView: 4,}, 1000: {slidesPerView: 5,}, 1300: {slidesPerView: 6,}}}
                        loop={true}
                        spaceBetween={20}
                        // centeredSlides={true}
                        modules={[Autoplay, Navigation]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <img src={images.certificate1} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={images.certificate2} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={images.certificate3} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={images.certificate4} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={images.certificate5} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={images.certificate6} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={images.certificate7} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={images.certificate8} />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={images.certificate9} />
                        </SwiperSlide>
                    </Swiper>


                </div>
            </Container>
        </div>
        </div>
    )
}

export default Home;